#header {
  background-image: url(/src/assets/PAG_01.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vw;
}

#header p {
  font-family: 'lemonmilk-bold', sans-serif;
  font-size: 3vw;
  color: white;
  text-align: right;
  text-shadow: 3px 3px 3px rgb(53, 46, 46);
  font-weight: 800;
}
