#formulario {
    transition: 500ms linear;
    width: 35vw;
}

#formulario:hover {
    transform: scale(1.1);
}

#formulario p {
    font-size: 3vw;
}

#formulario span {
    font-size: 2vw;
}

#formulario #botao {
    display: flex;
    text-align: center;
    justify-content: center;
}

@media (max-width: 800px) {
    #formulario {
        width: 70vw;
    }
  }