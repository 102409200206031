* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

.down {
  margin-top: 100vh;
}

/* #header, #info1, #info2, #footer {
  position: absolute;
  width: 100%;
} */
