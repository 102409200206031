#footer {
    background-image: url(/src/assets/PAG_05.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

#footer .container {
    padding: 30vw 0 5vw;
}

#footer .col-sm {
    text-align: center;
    display: flex;
    justify-content: center;
}

#footer .col-sm div {
    margin: 10px;

}

#footer .texto1 {
    color: #bd0000;
    font-family: "LEMON MILK Medium";
    text-transform: uppercase;
}

#footer .texto2 {
    color: red;
    white-space: nowrap;
}

#footer .texto3 {
    font-family: "LEMON MILK Medium";
    color: #bd0000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10vw;
}

#footer .icon {
    width: 3%;
    position: fixed;
    bottom: 2%;
    right: 5%;
    padding: 10px;
    background-color: #68B651;
    border-radius: 50px;
    z-index:1000;
}

@media (max-width: 800px) {
    #icon-whats {
        position: fixed;
    }

    #footer .icon {
        width: 7vw;
    }
}

@media (max-width: 500px) {
    #icon-whats {
        position: fixed;
    }

    #footer .icon {
        width: 14vw;
    }
}




