#info3 {
    background-image: url(/src/assets/PAG_03.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height:65vmax;
}

.d-inline{
    width: 12%;
    margin-left: -7%;
    margin-top: -14%;
}

.pki{
    text-align: center;
    margin-top: 3%;
    margin-bottom: 2%;
    font-size: 4vw;
    font-family: "LEMON MILK Medium";
    font-weight: bold;
    text-transform: uppercase;
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: 3;
}

.pkl{
    text-align: center;
    margin-top: 2%;
    font-size: 3vw;
    font-family: "LEMON MILK Medium";
    font-weight: bold;
    text-transform: uppercase;
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: 1;
    
}

.pky{
    font-size: 2vw;
    box-shadow: 0 0 10px 0 gray;
    text-align: left;
    padding: 20px;
    background-color: rgb(243,244,245);
    margin-top: 2%;
}

.xpt{
    color:black;
    font-weight: bolder;
}

.vps{
    color:gray;
    font-weight: bolder;
    font-size: 1.2vw;
}

a{
    text-decoration: none;
    color:gray;
}

.vpu{
    color:black;
    font-weight:100;
    font-size: 1.2vw;
}

.accordion-button{
    background-color: gray;
    opacity: 0.8;
    font-size: 3vw;
}

.xxl{
    width:10%;
    opacity: 1;
}

@keyframes example {
    0%   {scale: 0;}
    100% {scale: 1;}
  }




