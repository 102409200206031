#info1 {
    background-image: url(/src/assets/PAG_03.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vw;
    padding-top: 6vw;
}

#info1 p {
    font-family: "LEMON MILK Medium";
    font-size: 3vw;
    text-align: center;
    color: black;
    margin-bottom: 10px;
}

#info1 a {
    font-family: "Bebas Regular";
    font-size: 3vw;
    padding: 15px 30px;
    background-color: #666666;
    color: white;
    text-transform: uppercase;
    border-radius: 15px;
    border: none;
}

#info1 .texto {
    font-family: 'Futura-Medium', sans-serif;
    font-size: 3vw;
}

#info1 .texto-num {
    font-family: 'Futura-Medium', sans-serif;
    font-size: 4vw;
    font-weight: bold;
}

#info1 .testeV {
    font-family: 'Futura', sans-serif;
    font-weight: 1000;
    font-size: 2.5vw;
    white-space: nowrap;
}

#info1 .col {
    color: black;
}

