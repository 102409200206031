#info2 {
    background-color: #666666;
}

#info2 .card {
    background-color: inherit;
    border: none;
}

#info2 .titulo {
    font-family: "LEMON MILK Medium";
    font-weight: bold;
    text-transform: uppercase;
}

#info2 .descricao {
    font-weight: bold;
    font-size: 1.5vw;
}

#info2 a {
    font-family: "Bebas Regular";
    padding: 5px 16px;
    background-color: white;
    color: black;
    text-transform: uppercase;
    border-radius: 20px;
    border: none;
    font-size: 2vw;
}

#img1 {
    animation-duration: scroll;
    transition: 500ms linear;
}

#img1:hover {
    opacity: 100%;
}

.container {
    padding: 5%;
}

.card-body {
    color: #cccccc;
}